import { AdUnit } from "./ads-service";
import { ulid } from 'ulid';

declare global {
  interface Window {
    STORE?: {
      getState(): any;
    };
    __arkPlayWidget__?: {
      configObj?: {
        config?: any
      };
    };
  }
}

export class KeyValues {
  protected kv: Record<string, string> = {};

  constructor(
    protected href: () => string,
    protected storage: Storage,
    protected cookies: string,
  ) { }

  public setKeyValue(key: string, value: string) {
    this.kv[key] = value;
  }

  public getArray(adUnit: AdUnit) {
    return Object.entries({
      ...this.kv,
      ...adUnit.kv,
      elementId: adUnit.id,
      product: adUnit.product,
      dfpPrebid: '2',
      game_name: this.getGameName(),
      arkpromo: this.getArkPromo(),
      utm_campaign: this.getUtmCampaign(),
      ark_affiliate: this.getArkAffiliate(),
    });
  }

  public getPPID() {
    const ppid = this.storage.getItem('_ppid') || ulid();
    this.storage.setItem('_ppid', ppid);
    return ppid;
  }

  protected getGameName() {
    const xmlConfig = window.__arkPlayWidget__?.configObj?.config;
    if (xmlConfig) return xmlConfig['config-keys']['external-game-id'];

    const state = window.STORE?.getState();
    if (state) return state?.gameArena5Slug || state?.game?.slug;

    return (this.href().match(/games?\/([0-9A-z-]+)/) || [])[1];
  }

  protected getArkPromo() {
    return this.getFromStorageOrUrl('arkpromo');
  }

  protected getUtmCampaign() {
    return this.getFromStorageOrUrl('utm_campaign');
  }

  protected getArkAffiliate() {
    return this.getFromCookie('ark_affiliate');
  }

  protected getAdsABTest() {
    return this.getFromCookie('ark_abtest_variation');
  }

  protected getFromStorageOrUrl(key: string) {
    try {
      const fromLS = this.storage.getItem(key);
      if (fromLS) return fromLS;

      const [, fromURL] = this.href().match(new RegExp(`${key}=([0-9A-z_]+)`)) || [];
      if (fromURL) {
        this.storage.setItem(key, fromURL);
        return fromURL;
      }

      return;
    } catch (e) {
      return;
    }
  }

  protected getFromCookie(key: string) {
    const [, value] = this.cookies.match(new RegExp(key + '=([^;]*)')) || [];
    return value;
  }
}
