import { loadScript } from "../shared/loadScript"
import { Config } from "./config-service";

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return navigator.maxTouchPoints > 2 && /Mac/.test(navigator.platform);
  }
}

export class AditudeFloors {
  constructor(config: Config) {
    const isSubscriber = document.cookie.match('ark_subscription=true');
    if (!isSubscriber && config.isAditudeEnabled) {
      loadScript('https://dn0qt3r0xannq.cloudfront.net/arkadium-QKoDH9ov9c/arkadium/prebid-load.js');
    }
    const interval = setInterval(() => {
      const isSubscriber = document.cookie.match('ark_subscription=true');
      if (isSubscriber) {
        document.querySelector('aditude-outstream')?.remove();
        clearInterval(interval);
      }
    }, 1000);
    if (isIOS()) {
      setInterval(() => {
        document.querySelector('aditude-outstream')?.remove();
      }, 1000);
    }
  }
}
