import { OutstreamEnum } from "@arkadium/jarvis/dist/v2";
import { loadScript } from "../shared/loadScript";

export function runOutstream(type: OutstreamEnum) {
  const container = document.querySelector('display-ad-component[data-id="ark_display_r2"]') as HTMLDivElement;
  
  if (!container) {
    return killAditudePlayer();
  }

  switch (type) {
    case OutstreamEnum.aditude:
      break;
    case OutstreamEnum.exco:
      killAditudePlayer();
      runExco(container);
      break;
    case OutstreamEnum.kargo:
      killAditudePlayer();
      runKargo(container);
    case OutstreamEnum.house:
      killAditudePlayer();
      runHouse(container);
  }
}

function killAditudePlayer() {
  const interval = setInterval(() => {
    const player = document.querySelector('aditude-outstream');
    if (player) {
      player.remove();
      clearInterval(interval);
    }
  }, 500);
}

function runExco(container: HTMLDivElement) {
  loadScript('//player.ex.co/player/7d402f6f-f79b-4e92-b42a-7edc6c2e4af3');
  container.insertAdjacentHTML('afterend', '<div id="7d402f6f-f79b-4e92-b42a-7edc6c2e4af3"></div>');
}

function runKargo(container: HTMLDivElement) {
  loadScript('https://standout-cdn.kargo.com/js/rh.min.js');

}

function runHouse(container: HTMLDivElement) {
  console.warn('Not implemented yet.');
}
