import { AdsService, AdUnit } from "./services/ads-service";
import { AdConfig } from "./shared/ad-config";

export class DisplayAd extends HTMLElement {
  protected coreService = AdsService.getInstance();
  protected adUnit: AdUnit;
  protected adQueueItem: any; 

  constructor() {
    super();
    this.adQueueItem = { adConfig: new AdConfig };
    this.adUnit = {} as any;
  }

  public connectedCallback() {
    // attributes may change after constructor
    this.adUnit = this.convertAttributesToAdUnit();
    const adLabel = this.previousSibling?.textContent?.includes('Ad') ? '' : `<div style="position: absolute; left: 0; top: 0; opacity: .5; background: #fff; color: #000; font-size: 9px; padding: 2px 4px;">Ad</div>`;
    this.innerHTML = `<div id="${this.adUnit.id}" style="width: 100%; height: 100%; position: relative;"></div>${adLabel}`;
    this.updateStyles(this.adUnit);
    this.coreService.registerAdUnit(this.adUnit);
  }

  public disconnectedCallback() {
    this.coreService.unregisterAdUnit(this.adUnit);
  }

  public isViewable() {
    // This method is deprecated and will be removed in future
    console.warn('.isViewable() is deprecated.');
    return this.coreService.isAdUnitVisible(this.adUnit);
  }

  public refresh() {
    console.warn('.refresh() is deprecated.');
    // This method is deprecated and will be removed in future
  }

  protected convertAttributesToAdUnit(): AdUnit {
    return {
      id: this.attr('data-is') || this.attr('data-id'),
      size: this.attr('dimensions', true),
      kv: this.attr('keyvalues', true),
      product: this.attr('product'),
      lastRefreshed: 0,
    };
  }

  protected attr(name: string, parse = false) {
    const value = this.attributes.getNamedItem(name)?.value;

    if (!value) return;
    if (!parse) return value;

    try {
      return JSON.parse(value || '');
    } catch (e) {
      console.warn(`Can't parse supplied "${name}" attribute.`);
    }
  }

  protected updateStyles(adUnit: AdUnit) {
    try {
      this.style.position = 'relative';
      this.style.display = 'block';
      this.style.height = Math.max(...adUnit.size.map(([w, h]) => h)) + 'px';
      this.style.width = Math.max(...adUnit.size.map(([w, h]) => w)) + 'px';
    } catch (e) {

    }
  }
}
